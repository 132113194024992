import styled from "styled-components";

export const PageTitle = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`;

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
`;

export const Subtitle = styled.div`
  font-size: 21px;
  margin-bottom: 8px;
  display: inline-block;
  text-align: center;
`;

export const SubtitleTime = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: 400;
`;

export const SubtitleDeactivated = styled.div`
  font-size: 30px;
  padding-left: 11%;

  @media screen and (max-width: 376px) {
    font-size: 25px;
    width: 100%;
    text-align: center;
  }

  @media screen and (max-width: 394px) {
    font-size: 25px;
    width: 100%;
    text-align: center;
  }
`;

export const ContainerOnceOff = styled.div`
  padding-left: 3%;
`;

/* Form */
export const FormGroupLabel = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-top: 32px;
`;

export const FormSubGroupLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 32px;
`;

interface ContainerProps {
  direction: string;
}

export const Error = styled.span<ContainerProps>`
  font-weight: normal;
  color: ${(props) => props.theme.form.errorColor};
  margin-top: 4px;
  padding-left: ${(props) => (props.direction === "left" ? "145px" : "0")};
  display: flex;
  align-items: center;

  ${(props) =>
    props.direction === "auto"
      ? `
    @media (min-width: 768px) {
    padding-left: 145px;
  }`
      : ""}
`;

export const Warning = styled.span<ContainerProps>`
  font-weight: normal;
  font-style: italic;
  color: ${(props) => props.theme.form.warningColor};
  margin-top: 4px;
  padding-left: ${(props) => (props.direction === "left" ? "145px" : "0")};
  display: flex;
  align-items: center;
  font-size: 13px;
  ${(props) =>
    props.direction === "auto"
      ? `
    @media (min-width: 768px) {
    padding-left: 20px;
  }`
      : ""}
`;

export const IconWrapper = styled.span`
  display: flex;
`;
