import styled from "styled-components";
import { palette } from "../../themes/palette";
import MDEditor from "@uiw/react-md-editor";
import { Divider, Menu } from "@mui/material";
import { Button } from "react-bootstrap";

export const AudienceType = styled.div`
    background-color: ${palette.purple};
    color: white;
    border-radius: 10px;
    padding: 4px;
`;

export const AudienceTypeContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
`;

export const LegendIcon = styled.div`
    height: 100%;
    min-width: 25px;
    background-color: ${palette.azalea};
    border-radius: 5px;
`;

export const LegendIconContainer = styled.div`
    width: 100%;
    gap: 5px;
    display: inline-flex;
`;


export const AnnouncementFormHeader = styled.div`
    text-align: center;
    h1 {
      font-size: 24px;
      font-style: inherit;
      line-height: 1.103448275862069;
      color: #172B4D;
      font-weight: 500;
      letter-spacing: -0.01em;
    }
`;

export const AnnouncementFormContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`

export const AnnouncementFormBodyContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
`;

export const AnnouncementFormButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 80%;
    padding-top: 20px;
`;

export const InlineContainer = styled.div`
    display: flex;
    gap: 20px;
    width: 100%;
    padding-top: 40px;
`;

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;    
`; 

export const ValidationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: red;
    gap: 3px;
`;

export const MarkdownEditor = styled(MDEditor)`
    width: 100%;

    .w-md-editor-text{
        height: 100%;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
`;

export const BadgeButton = styled(Button)`
    width: 40px;
    height: 40px;
    padding: 0;
    background-color: transparent;
    border: 0;
    border-radius: 3px;
    &:hover,
    &:focus{
        background-color: transparent !important;
    }
`;

export const StyledMenu = styled(Menu)`
    margin-left: 14px;
    & .MuiPaper-root {
        background-color: ${palette.navy};
        color: white;
        max-height: 30%;
        scrollbar-width: thin;
        scrollbar-color: #5f5bea #000024;
    }
`;

export const StyledDivider = styled(Divider)`
    height: 1px;
    background-color: white;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
    opacity: .1;
`;