import React from "react";
import styled from "styled-components";
import Breadcrumbs, { BreadcrumbItemProps } from "./Breadcrumbs";
import Button, { ButtonGroup } from "./Button";
import { PageTitle, Subtitle } from "./Typography";
import Loader from "./Loader"

const PageTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 420px) and (orientation: portrait), (max-height: 390px) and (orientation: landscape) {
    flex-direction: column;
    align-items: center;
  }
`;
const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 0px;
`;

interface Props {
  pageTitle?: string;
  subTitle?: string;
  breadcrumbLinks?: Array<BreadcrumbItemProps>;
  isShowButtons?: boolean;
  children?: React.ReactNode;
  onCancel?: () => void;
  onSave?: () => void;
  loading?: boolean
}

const PageTitleWithButtons: React.FunctionComponent<Props> = ({
  pageTitle,
  subTitle,
  breadcrumbLinks,
  isShowButtons,
  children,
  onCancel,
  onSave,
  loading
}) => {
  return (
    <PageTitleContainer>
      <div>
        {breadcrumbLinks && breadcrumbLinks.length > 0 && (
          <Breadcrumbs links={breadcrumbLinks} />
        )}
        <PageTitle>{pageTitle}</PageTitle>
        {subTitle && (<Subtitle>{subTitle}</Subtitle>)}
      </div>
      {isShowButtons ? (
        <StyledButtonGroup>
          <Button appearance="cancel" onClick={onCancel}>
            Cancel
          </Button>
          <Button appearance="primary" onClick={onSave}>
          {loading ? (
            <Loader small form display="flex" />
          )  : (
            "Save"
          )}
          </Button>
        </StyledButtonGroup>
      ) : (
        children
      )}
    </PageTitleContainer>
  );
};

export default PageTitleWithButtons;
