import React from "react";
import { ButtonGroupProps } from "./types";
import styled from "styled-components";

const Container = styled.div`
  display: inline-flex;
  margin-top: 32px;

  @media (max-width: 420px) and (orientation: portrait), (max-height: 390px) and (orientation: landscape) {
    width: 100%;
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  flex: 1 0 auto;
  display: flex;

  & + &::before {
    content: "";
    display: inline-block;
    width: 8px;
  }
  @media (max-width: 420px) and (orientation: portrait), (max-height: 390px) and (orientation: landscape) {
    width: 100%;
    justify-content: center;

    &:last-child,
    &:first-child {
      order: -1;
    }
  }
`;

export default class ButtonGroup extends React.PureComponent<ButtonGroupProps> {
  render() {
    const { children, ...atributes } = this.props;

    return (
      <Container {...atributes}>
        {React.Children.map(children, (child, index) => {
          if (!child) {
            return null;
          }
          return <Wrapper key={index}>{child}</Wrapper>;
        })}
      </Container>
    );
  }
}
