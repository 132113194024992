import styled from "styled-components";

const Divider = styled.div`
  display: flex;
  background: ${(props) => props.theme.navigator.main.item.active.background};
  height: 2px;
  margin: 8px;
  border-radius: 1px;
`;

export default Divider;
