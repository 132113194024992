/*
 * CONSTANTS
 */

import i18next from "i18next";

interface LANGUAGE_LABELS_INTERFACE {
  [key: string]: string;
}

export const LANGUAGE_LABELS: LANGUAGE_LABELS_INTERFACE = {
  ru: "Rus",
  en: "Eng",
};

/*
 * ENUMERATIONS
 * Enums allow us to organize a collection of related values. Think of them as
 * a class for values, wherein the value can only be a string , or number.
 *
 */

export enum ROUTES {
  home = "/",
  map = "/map",
  dictionaries = "/dictionaries",
  profile = "/profile",
  siteCreate = "/site/new",
  site = "/sites/:id",
  siteInfo = "/sites/:id/info",
  siteCameras = "/sites/:id/cameras",
  siteConfirmCameras = "/sites/:id/cameras/confirm/:cameraCount",
  siteZones = "/sites/:id/zones",
  siteZone = "/sites/:id/zones/:zoneId",
  siteAddress = "/sites/:id/address",
  siteContacts = "/sites/:id/contacts",
  siteContact = "/sites/:id/contacts/:contactId",
  siteAlarmResponse = "/sites/:id/alarmResponse",
  siteVisitors = "/sites/:id/visitors",
  siteVisitor = "/sites/:id/visitors/:visitorId",
  siteHistory = "/sites/:id/siteHistory",
  //id at end
  siteVisitorPass = "/sitevisitorpass/:guid/:operatorId?",
  sitePlan = "/sites/:id/plan",
  siteArming = "/sites/:id/arming",
  page403 = "/403",
  page404 = "/404",
  page500 = "/500",
  //armingChecks
  armingCheck = "/sites/:id/armingChecks/:checkId",
  armingChecks = "/sites/:id/armingChecks",
  //tasks
  task = "/serviceRequests/:id",
  taskInfo = "/serviceRequests/:id/info",
  taskCreate = "/serviceRequests/new",
  //main
  tasks = "/serviceRequests",
  sites = "/sites",
  reports = "/reports",
  interactiveReports = "/reports/interactive-reports/:reportId",
  operator = "/operator",
  //setPassword
  setPassword = "/SetPassword/:hash",
  //auth
  login = "/login",
  rememberPasswordStep1 = "/remember-password/step1",
  rememberPasswordStep2 = "/remember-password/step2",
  rememberPasswordStep3 = "/remember-password/step3",
  //admins
  users = "/users",
  contact = "/contact/:contactId",
  userCreate = "/user/new",
  user = "/users/:id",
  userInfo = "/users/:id/info",
  //dealers
  dealers = "/dealers",
  dealerCreate = "/dealer/new",
  dealer = "/dealers/:id",
  dealerInfo = "/dealers/:id/info",
  visitorPassBranding = "/dealers/:id/visitorPassBranding",
  dealerMetrics = "/dealers/:id/metrics",
  //customers
  customers = "/customers",
  customerCreate = "/customer/new",
  customer = "/customers/:id",
  customerInfo = "/customers/:id/info",
  customerMetrics = "/customers/:id/metrics",

  communications = "/communications",
  training = "/training",
  siteProposals = "/siteProposals",

  sharedContacts = "/shared-contacts/",
  sharedContact = "/shared-contacts/:id",
  //Change Password
  changePassword = "/change-password/",

  logs = "/logs",
  log = "/logs/:id",
  dashboard = "/dashboard",
  //Commissioning
  commissioningMenu = "/commissioning-menu",
  commissioning = "/commissioning-menu/commissioning",
  commissioningInfo = "/commissioning-menu/commissioning/:id",
  decommissioningInfo = "/commissioning-menu/commissioning-requests/:id",
  commissioningApproval = "/commissioning-menu/commissioning-approval",
  commissioningWalkTest = "/commissioning-menu/commissioning-walktest",
  commissioningWalkTestInfo = "/commissioning-menu/:id/commissioning-walktest-info",
  commissioningWalkTests = "/commissioning-menu/commissioning-walktests",
  commissioningScheduledSystemTests = "/commissioning-menu/commissioning-extendedsystemtests",
  commissioningScheduledSystemTestCreate = "/commissioning-menu/commissioning-extendedsystemtestcreate",
  commissioningMSUs = "/commissioning-menu/commissioning-msus",
  commissioningMSUInfo = "/commissioning-menu/:id/commissioning-msu-info",
  commissioningRequests = "/commissioning-menu/commissioning-requests",
  //Instant Audio Schedule
  instantAudioSchedule = "/sites/:id/instantAudioSchedule",

  //Dealer Settings
  operatorSettings = "/operator-settings",
  eagleEyeCredentials = "/eagleeye-credentials",

  //Policy Admin Panel
  policyAdminPanel = "/policy-admin-panel/",

  //Redirect links to App Stores
  appStoreDownload = "/appstore",

  //Shareable Link
  sharedInteractiveReport = "/shared/interactive-report/:reportId/:accessToken",

  //SceneSpecificAudio
  sceneSpecificAudio = "/scene-specific-audio",

  //Announcements
  announcements = "/announcements",
  createAnnouncement = "/announcements/new",
  editAnnouncement = "/announcements/:id",
  
  //Day and night images
  qualityAssurance = "/qualityAssurance",
  cameraQA = qualityAssurance + "/cameraQA",
}

export enum LOCAL_STORAGE_TEMPLATE {
  accessToken = "access-token",
  refreshToken = "refresh-token",
}

const DEFAULT_WEEKDAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const WEEKDAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const WEEKDAYS_SHORT = WEEKDAYS.map((d: string) =>
  d.substring(0, 3).toLowerCase()
);

export const TODAY = new Date(new Date().setHours(0, 0, 0, 0));
export const TODAY_INDEX = WEEKDAYS.indexOf(DEFAULT_WEEKDAYS[TODAY.getDay()]);
export const START_DATE_OF_CURRENT_WEEK = new Date(
  new Date().setDate(TODAY.getDate() - TODAY_INDEX)
);
export const TIMES_DEFAULT = new Array(24)
  .fill(0)
  .map((e, i) => `0${i}:00`.slice(-5));

export const TIME_SLOTS = ["00", "15", "30", "45"];

export const MaterialReactTableLocalization = () => {
  return {
    filterByColumn: `${i18next.t("filterBy")} {column}`,
    columnActions: i18next.t("columnActions"),
    clearFilter: i18next.t("clearFilter"),
    sortByColumnAsc: `${i18next.t("sortBy")} {column} ${i18next.t(
      "ascending"
    )}`,
    sortedByColumnAsc: `${i18next.t("sortedBy")} {column} ${i18next.t(
      "ascending"
    )}`,
    sortByColumnDesc: `${i18next.t("sortBy")} {column} ${i18next.t(
      "descending"
    )}`,
    sortedByColumnDesc: `${i18next.t("sortedBy")} {column} ${i18next.t(
      "descending"
    )}`,
    clearSort: i18next.t("clearSort"),
    resetColumnSize: i18next.t("resetColumnSize"),
    hideColumn: `${i18next.t("hide")} {column} ${i18next.t("column")}`,
    showAllColumns: i18next.t("showAllColumns"),
    clearSearch: i18next.t("clearSearch"),
    search: i18next.t("search"),
    showHideFilters: i18next.t("showHideFilters"),
    showHideColumns: i18next.t("showHideColumns"),
    toggleDensity: i18next.t("toggleDensity"),
    hideAll: i18next.t("hideAll"),
    showAll: i18next.t("showAll"),
    noRecordsToDisplay: i18next.t("noRecordsToDisplay"),
    noResultsFound: i18next.t("noResultsFound"),
    expandAll: i18next.t("expandAll"),
    expand: i18next.t("expand"),
    toggleFullScreen: i18next.t("toggleFullScreen"),
    showHideSearch: i18next.t("showHideSearch"),
    rowsPerPage: i18next.t("rowsPerPage"),
    goToFirstPage: i18next.t("goToFirstPage"),
    goToPreviousPage: i18next.t("goToPreviousPage"),
    goToNextPage: i18next.t("goToNextPage"),
    goToLastPage: i18next.t("goToLastPage"),
    of: i18next.t("of"),
    cancel: i18next.t("cancel"),
    save: i18next.t("save"),
  };
};
