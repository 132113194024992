import { CUSTOMER_BASIC_RESTRICTIONS } from "./customerBasicRestrictions";
import { CUSTOMER_MANAGER_RESTRICTIONS } from "./customerManagerRestrictions";
import { CUSTOMER_ADMIN_RESTRICTIONS } from "./customerAdminRestrictions";
import { DEALER_REGULAR_RESTRICTIONS } from "./dealerRestrictions";
import { COMMISSIONING_DEALER_REGULAR_RESTRICTIONS } from "./commissioningDealerRestrictions";

export function getRestrictionsByRole(userRole: string) {
  switch (userRole) {
    case "CustomerManager":
      return CUSTOMER_MANAGER_RESTRICTIONS;
    case "CustomerReadOnly":
    case "CustomerBasic":
      return CUSTOMER_BASIC_RESTRICTIONS;
    case "Arm/DisarmOnly":
    case "CustomerAdmin":
      return CUSTOMER_ADMIN_RESTRICTIONS;
    case "CLIENT-PROJECT-SPECIALIST":
    case "Commissioning":
      return [];
    case "Customer-Care":
    case "Dealer":
      return DEALER_REGULAR_RESTRICTIONS;
    case "CommissioningDealer":
      return COMMISSIONING_DEALER_REGULAR_RESTRICTIONS;
    case "DESIGNER":
    case "FIELD":
    case "FINANCE":
    case "Hub":
    case "INSTALLER":
    case "Marketing":
    case "Sales":
    case "System Admin":
    case "TECHNICAL":
      return DEALER_REGULAR_RESTRICTIONS;
    default:
      return [];
  }
}
