import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import NavigationItem from "./NavigationItem";
import { GlobalNavigationInterface, NavigationItemInterface } from "./types";
import Burger from "./Burger";
import Avatar from "../Avatar";
import Divider from "./NavigationDivider";
import CompactNavigation from "./CompactNavigation";
import { useCollapsed } from "../../hooks/useCollapsed";
import { useBurgerOpened } from "../../hooks/useBurgerOpened";
import { ROUTES } from "../../constants";
import { config } from "../../containers/Config";
import AnnouncementBadge from "../../containers/Announcements/Components/AnnouncementBadge";
import i18next from "i18next";

const Container = styled.div<{ isCollapsed: Boolean; forceShow: Boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background: ${(props) => props.theme.navigator.main.background};
  width: ${(props) => (props.isCollapsed ? 64 : 224)}px;
  transition: width 0.25s;

  @media (max-width: 420px) and (orientation: portrait),
    (max-height: 390px) and (orientation: landscape) {
    display: ${(props) => (props.forceShow ? "flex" : "none")};
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #5f5bea #000024;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: #000024;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #5f5bea;
    border-radius: 2px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #3b3991;
    border-radius: 2px;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0px;
  gap: 5px;
`;

const GroupHeader = styled.span<{ isCollapsed: Boolean }>`
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${(props) => props.theme.navigator.main.item.default.color};
  display: ${(props) => (props.isCollapsed ? "none" : "flex")};
  transition: display 1s;
  padding: 20px 8px 14px 20px;
  width: 100%;
`;

const VersionFooter = styled.span<{ isCollapsed: Boolean }>`
  font-size: 10px;
  line-height: 11px;
  color: ${(props) => props.theme.navigator.main.item.default.color};
  display: ${(props) => (props.isCollapsed ? "none" : "flex")};
  transition: display 1s;
  padding: 2px 20px 2px 20px;
  width: 100%;
`;

const AvatarContainer = styled.div`
  display: flex;
  padding-right: 12px;
`;

const AvatarNavigationItem = styled(NavigationItem)`
  display: flex;
  flex: 1;
  padding: ${(props) => (props.isCollapsed ? "0 4px" : "0 6px")};
  height: 34px;
`;

const ProfileNavigationItem = styled(NavigationItem)`
  white-space: normal;
  height: 34px;
`;

const HelpNavigationItem = styled(NavigationItem)`
  display: flex;
  white-space: normal;
  height: 34px;
`;

const LogoutNavigationItem = styled(NavigationItem)`
  padding: 0 3px 0 6px;
  margin: 0;
  border-radius: 3px;
  height: 34px;
`;

const StyledLink = styled(Link)`
  align-self: center;
  border-width: 0;
  outline: none !important;
  &::-moz-focus-inner {
    border: 0;
    margin: 0;
    padding: 0;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`;

const StyledLogoImg = styled.img<{ isCollapsed: boolean }>`
  width: ${(props) => (props.isCollapsed ? "100%" : "224px")};
  margin-top: ${(props) => (props.isCollapsed ? "12px" : "0px")};
`;

const HelpContainer = styled.div<{isCollapsed: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isCollapsed ? "column" : "row")};
  gap: 5px;
  justify-content: ${(props) => (props.isCollapsed ? "center" : "flex-start")};
  align-items: center;
  padding-right: 12px;
`;

function GlobalNavigation(props: GlobalNavigationInterface) {
  const key = "isGlobalCollapsed";
  const { isCollapsed, saveCollapse } = useCollapsed(key);
  const { isBurgerOpened, saveBurgerOpened } = useBurgerOpened();

  const { main, admin, help, profile, onClickProfile, onClickLogout } = props;

  const profileMenu: NavigationItemInterface[] = [
    { label: profile?.fullName ? profile.fullName : "", type: "title" },
    { label: "Edit profile", to: ROUTES.profile },
    { label: `Version: ${config().REACT_APP_BUILD_VERSION}`, type: "text" },
  ];

  const onBurgerClicked = () => {
    var local = !isBurgerOpened;
    saveBurgerOpened(local);
  };

  return (
    <Fragment>
      <Burger onBurgerClick={onBurgerClicked} opened={isBurgerOpened} />
      <Container isCollapsed={isCollapsed} forceShow={isBurgerOpened}>
        <Wrapper>
          <StyledLink to="/">
            <StyledLogoImg
              isCollapsed={isCollapsed}
              src={`/images/logo-white${
                isCollapsed ? "-small" : "-horizontal"
              }.png`}
              srcSet={`/images/logo-white${
                isCollapsed ? "-small" : "-horizontal"
              }.png 1x, /images/logo-white${
                isCollapsed ? "-small" : "-horizontal"
              }@2x.png 2x`}
              alt="Netwatch logo"
            />
          </StyledLink>
          {main &&
            main.length &&
            main.map((itm, index) => (
              <NavigationItem
                isShown={itm?.isShown}
                isCollapsed={isCollapsed}
                key={`gNavItm_${index}`}
                {...itm}
              />
            ))}
          {admin && admin.length && admin.length > 0 && (
            <>
              <GroupHeader isCollapsed={isCollapsed}>
                {i18next.t('administration')}
              </GroupHeader>
              {admin.map((itm, index) => (
                <NavigationItem
                  isCollapsed={isCollapsed}
                  key={`gNavItm_admin_${index}`}
                  {...itm}
                />
              ))}
            </>
          )}
        </Wrapper>
        <FooterWrapper>
          <HelpContainer isCollapsed={isCollapsed}>
            <CompactNavigation options={help}>
              <HelpNavigationItem
                isCollapsed={isCollapsed}
                label={i18next.t('help')}
                iconName="help"
              />
            </CompactNavigation>
            <AnnouncementBadge />
          </HelpContainer>

          {isCollapsed ? (
            <>
              <CompactNavigation options={profileMenu}>
                <AvatarNavigationItem
                  isCollapsed={true}
                  label={i18next.t('profile')}
                  icon={<Avatar src={profile?.avatarImg} size={32} />}
                  onClick={onClickProfile}
                />
              </CompactNavigation>
              <NavigationItem
                isCollapsed={true}
                label={i18next.t('logout')}
                iconName="logout"
                onClick={onClickLogout}
              />
            </>
          ) : (
            <AvatarContainer>
              <ProfileNavigationItem
                label={profile?.fullName ? profile.fullName : "Profile"}
                icon={<Avatar src={profile?.avatarImg} size={32} />}
                to={ROUTES.profile}
              />
              <LogoutNavigationItem
                isCollapsed={true}
                label={i18next.t('logout')}
                iconName="logout"
                onClick={onClickLogout}
              />
            </AvatarContainer>
          )}
          <Divider />
          <NavigationItem
            isCollapsed={isCollapsed}
            label={isCollapsed ? i18next.t("expand") : i18next.t("collapse")}
            iconName={isCollapsed ? "chevron-right" : "chevron-left"}
            onClick={() => saveCollapse(!isCollapsed)}
          />
          <VersionFooter isCollapsed={isCollapsed}>
            {"Version: " + config().REACT_APP_BUILD_VERSION}
          </VersionFooter>
        </FooterWrapper>
      </Container>
    </Fragment>
  );
}

export default GlobalNavigation;
