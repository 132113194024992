import { useEffect, useState } from 'react';

const key: string = "isBurgerOpened";

export const useBurgerOpened = () => {
  const [isBurgerOpened, setBurgerOpened] = useState(false);

  useEffect(() => {
    const value = sessionStorage.getItem(key);
    if (value) {
      setBurgerOpened(value == 'true');
    }
  }, [])

  const saveBurgerOpened = (value: boolean): void => {    
    setBurgerOpened(value);
    sessionStorage.setItem(key, `${value}`);
  }

  return {
    isBurgerOpened,
    saveBurgerOpened
  }
}
