import { useApolloClient } from "@apollo/client";
import React from "react";
import Icon from "../../../components/Icon";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import Button from "../../../components/Button";
import { palette } from "../../../themes/palette";
import { GET_REPORT_LINK } from "../gql";
import { config } from "../../Config";
import i18next from "i18next";

interface DownloadButtonProps {
  fileName?: string;
  customerId?: string;
  isInteractive?: boolean;
}

const Container = styled.div`
  display: flex;
`;

const CircleLoader = styled.div`
  padding-left: 10px;

  .nb-spinner {
    width: 32px;
    height: 32px;
    margin: 0;
    background: transparent;
    border-top: 4px solid ${palette.skyBlue};
    border-right: 4px solid transparent;
    border-radius: 50%;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
  }

  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const DownloadButton: React.FC<DownloadButtonProps> = ({
  fileName,
  customerId,
  isInteractive,
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [guid, setGuid] = React.useState<string>();
  const isMobileScreen = useMediaQuery({
    query:
      "(max-width: 420px) and (orientation: portrait), (max-height: 390px) and (orientation: landscape)",
  });

  const client = useApolloClient();

  const GetLink = React.useCallback(async () => {
    await setLoading(true);
    try {
      const { data } = await client.query({
        query: GET_REPORT_LINK,
        variables: { fileName, customerId },
        fetchPolicy: "network-only",
      });
      if (data?.siteIncidentReportLink) {
        setGuid(data.siteIncidentReportLink);
      }
    } catch (err) {
      console.error("DownloadButton / Error", err);
    }
    setLoading(false);
  }, [client, customerId, fileName]);

  React.useEffect(() => {
    if (guid) {
      window.open(`${config().REACT_APP_INCIDENT_REPORT_URL}${guid}`);
    }
  }, [guid]);

  return (
    <Container>
      {loading ? (
        <CircleLoader>
          <div className="nb-spinner"></div>
        </CircleLoader>
      ) : (
        <Button
          isExternalLink={true}
          isFetching={loading}
          disabled={loading}
          onClick={GetLink}
          appearance={isInteractive ? "primary" : "default"}
        >
          {isMobileScreen ? <Icon name="documents" /> : i18next.t("viewPDF")}
        </Button>
      )}
    </Container>
  );
};

export default React.memo(DownloadButton);
