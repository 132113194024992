import { useEffect, useState } from 'react';

export const useCollapsed = (key: string) => {
  const [isCollapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const value = sessionStorage.getItem(key);
    if (value) {
      setCollapsed(value == 'true');
    }
  }, [])

  const saveCollapse = (value: boolean): void => {
    const event = new CustomEvent("cusromResize", {
      detail: {
        isResize: true
      }
    });
    window.dispatchEvent(event);
    setCollapsed(value);
    sessionStorage.setItem(key, `${value}`);
  }

  return {
    isCollapsed,
    saveCollapse
  }
}
