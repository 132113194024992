import { useHistory } from 'react-router-dom';
import { FORM_FIELDS_LOGS } from './LogDetail';
import { FormFieldProps } from './../../components/FormFields/types';
import { useGetLogs } from './useGetLogs';

type Controls = 'prev' | 'next';

export const useGetFields = (id: string) => {
  const history = useHistory();
  const { logs } = useGetLogs();
  const itemIndex = logs.findIndex((log) => log.id === id);

  const addValuesToFields = (fields: FormFieldProps[]): FormFieldProps[] => {
    return fields.map((field) => {
      const logItem = logs[itemIndex];
      const value = logItem?.[field.name!];
      return { ...field, value };
    });
  };

  const isMakeDisabled = (type: Controls): boolean => {
    if (type === 'prev') {
      return itemIndex === 0;
    }
    return itemIndex === logs.length - 1;
  }

  const changeLog = (type: Controls) => {
    if (isMakeDisabled(type)) return;
    let logId = logs[itemIndex + 1]?.id;
    if (type === 'prev') {
      logId = logs[itemIndex - 1]?.id;
    }
    history.push('/logs/' + logId);
  }

  const fields = addValuesToFields(FORM_FIELDS_LOGS);
  return {
    fields,
    changeLog,
    isMakeDisabled
  }
}