export const CUSTOMER_MANAGER_RESTRICTIONS: string[] = [
  "cameras_name_edit",
  "sitePlan_changeBackground",
  "sitePlan_unallocatedCameras",
  "sitePlan_edit",
  "zones_add",
  "zones_draganddrop",
  "zones_edit",
  "zones_remove",
  "zoned_audio",
];
