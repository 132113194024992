import { Modal } from "react-bootstrap";
import Button from "../../../components/Button";
import MDEditor from "@uiw/react-md-editor";

type AnnouncementModalProps = {
    title: string;
    content: string;
    onClose: () => void;
};

const AnnouncementModal = (props: AnnouncementModalProps) => {
    
    return (
        <Modal
            show={true}
            size="lg"
            centered
            onHide={props.onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {`Announcement - ${props.title}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MDEditor.Markdown source={props.content} />
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" onClick={props.onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AnnouncementModal;