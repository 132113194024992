import React, { FunctionComponent, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

interface DialogProps {
  children: React.ReactElement;
  open?: boolean;
  right?: boolean;
  handleClose?: () => void;
}

const Container = styled.div<{ right?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: ${(props) => (props.right ? "right" : "center")};
  align-items: center;
  background-color: ${(props) => props.theme.popupBkg};
  z-index: 700;
`;

export const Dialog: FunctionComponent<DialogProps> = (
  props
): null | React.ReactElement => {
  const { open = false, handleClose, children } = props;

  const container = useRef<HTMLElement>(document.body);

  const onClickClose = (event: React.MouseEvent): void => {
    if (event.currentTarget === event.target) {
      if (handleClose) {
        handleClose();
      }
    }
    event.stopPropagation();
  };

  const dialog = (
    <Container right={props.right} onClick={onClickClose}>
      {children}
    </Container>
  );

  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(dialog, container.current);
};
