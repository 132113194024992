import { gql } from '@apollo/client';

export const GLOBAL_SEARCH = gql`
  query ($search: String!) {
    globalSearch(search: $search) {
      sites {
        id
        name
      }
      users {
        id
        firstName
        lastName
      }
      reports {
        id
        customerId
        fileName
      }
    }
  }
`;
