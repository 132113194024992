import React, { useEffect, useState } from "react";
import { AvatarPropTypes } from "./types";
import styled from "styled-components";
import { ReactComponent as DefaultAvatar } from "../../assets/images/default-avatar.svg";
import { useGetFile } from "../../hooks/useGetFile";

interface ContainerProps {
  size?: number;
  onClick?: () => void;
}

const Container = styled.div<ContainerProps>`
  background-color: ${(props) => props.theme.avatar.background};
  width: ${(props) => (props.size ? props.size : 32)}px;
  height: ${(props) => (props.size ? props.size : 32)}px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  svg {
    & path {
      fill: ${(props) => props.theme.avatar.color};
    }
    & g {
      fill: ${(props) => props.theme.avatar.background};
    }
  }
`;

const Image = styled.span<{ $src?: string }>`
  background-color: ${(props) => props.theme.avatar.background};
  background-image: ${(props) =>
    props.$src ? `url(${props.$src})` : undefined};
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
`;

const Avatar: React.FC<AvatarPropTypes> = ({
  name,
  src,
  size = 32,
  onClick,
}) => {
  const [imgSrc, setSrc] = useState("");

  const isValidHttpUrl = (str: string) => {
    let url;
    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };

  const fetchImage = async () => {
    try {
      if (src) {
        if (isValidHttpUrl(src)) {
          await fetch(src).then((val) => {
            setSrc(src);
          });
        } else {
          setSrc(src);
        }
      }
    } catch (err) {
      setSrc("");
    }
  };

  useEffect(() => {
    fetchImage();
  }, [src]);

  return (
    <Container size={size} onClick={onClick}>
      <DefaultAvatar />
      {imgSrc && <Image $src={imgSrc} role="img" aria-label={name} />}
    </Container>
  );
};

export default Avatar;
