import { gql } from "@apollo/client";

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($accessToken: String!, $refreshToken: String!) {
    refreshToken(accessToken: $accessToken, refreshToken: $refreshToken) {
      accessToken
      refreshToken {
        tokenString
      }
    }
  }
`;
