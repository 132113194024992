import { removeItem, setItem, getItem } from "../clientStorage";
import { LOCAL_STORAGE_TEMPLATE } from "../../constants";

export type AuthTokens = {
  accessToken: string;
  refreshToken: string;
};

let authTokens: AuthTokens | null = null;

export const getAccessToken = async () => {
  if (authTokens && authTokens.accessToken) {
    return authTokens.accessToken;
  }
  return await getItem(LOCAL_STORAGE_TEMPLATE.accessToken);
};

export const getTokens = async () => {
  if (authTokens) {
    return authTokens;
  }
  try {
    const accessToken = await getItem(LOCAL_STORAGE_TEMPLATE.accessToken);
    const refreshToken = await getItem(LOCAL_STORAGE_TEMPLATE.refreshToken);
    if (accessToken && refreshToken) {
      authTokens = { accessToken, refreshToken };
      return { accessToken, refreshToken };
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const saveTokens = async ({ accessToken, refreshToken }: AuthTokens) => {
  authTokens = { accessToken, refreshToken };
  try {
    await setItem(LOCAL_STORAGE_TEMPLATE.accessToken, accessToken);
    await setItem(LOCAL_STORAGE_TEMPLATE.refreshToken, refreshToken);
  } catch (err) {
    console.log("Failed saveTokens");
  }
};

export const removeTokens = async () => {
  await removeItem(LOCAL_STORAGE_TEMPLATE.accessToken);
  await removeItem(LOCAL_STORAGE_TEMPLATE.refreshToken);
  authTokens = null;
};

export const saveSharedToken = async (accessToken: string) => {
  await removeTokens();
  authTokens = { accessToken, refreshToken: "" };
  await setItem(LOCAL_STORAGE_TEMPLATE.accessToken, accessToken);
};
