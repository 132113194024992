import { gql } from "@apollo/client";

export const GET_ANNOUNCEMENTS = gql`
  query (
    $first: Int
    $after: String
    $order: [AnnouncementSortInput!] 
    $where: AnnouncementFilterInput
    ) {
      announcements(
      first: $first
      after: $after
      order: $order
      where: $where
    ) {
    nodes {
      id
    }
    edges{
      cursor
      node {
       id
       name
       uTCDateSent
       endDate
       acknowledgements{
        userId
       }
       audience{
        audienceType{
          id
          displayName
        }
       }
       item{
        details
       }
      }
    }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_LATEST_ANNOUNCEMENTS = gql`
query (
  $first: Int
  $after: String
  $order: [AnnouncementSortInput!] 
  $where: AnnouncementFilterInput
  ) {
    latestAnnouncements(
    first: $first
    after: $after
    order: $order
    where: $where
  ) {
  nodes {
    id
  }
  edges{
    cursor
    node {
     id
     name
     uTCDateSent
     endDate
     acknowledgements{
      userId
     }
     audience{
      audienceType{
        id
        displayName
      }
     }
     item{
      details
     }
    }
  }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
`;

export const GET_ANNOUNCEMENT_AUDIENCE_TYPES = gql`
  query {
    announcementAudienceTypes{
        id
        displayName
    }
  }
`;

export const CREATE_ANNOUNCEMENT = gql`
  mutation($audienceTypes: [Int!]!, $title: String!, $content: String!, $endDate: DateTime) {
    createAnnouncement(input:{
      content: $content
      name: $title
      audienceTypeIds: $audienceTypes
      endDate: $endDate
    })
    {
      id
    }
  }
`;

export const ACKNOWLEDGE_ANNOUNCEMENT = gql`
  mutation($announcementId: Int!){
    acknowledgeAnnouncement(id: $announcementId)
  }
`;

export const EDIT_ANNOUNCEMENT = gql`
  mutation($announcementId: Int!, $audienceTypes: [Int!]!, $title: String!, $content: String!, $endDate: DateTime) {
    editAnnouncement(
     id: $announcementId,
     input:{
      content: $content
      name: $title
      audienceTypeIds: $audienceTypes
      endDate: $endDate
    })
    {
      id
    }
  }
`;

export const DELETE_ANNOUNCEMENT = gql`
  mutation($announcementId: Int!){
    deleteAnnouncement(id: $announcementId)
  }
`;