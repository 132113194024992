import styled from "styled-components";
import { palette } from "../../themes/palette";

export const StyledBurger = styled.button<{ open: Boolean }>`
  position: absolute;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  ${({ open }) =>
    !open &&
    `
    top: 8px;
    left: 8px;
    width: 16px;
    height: 16px;
    div {
    width:16px;
    height: 0.25rem;
    background: ${palette.black};
    border-radius: 10px;
    transition: all 0.1s linear;
    position: relative;
    transform-origin: 1px;
    }
  `}

  ${({ open }) =>
    open &&
    `
    top: 8px;
    left: 8px;
    width: 16px;
    height: 16px;
    div {
    width:16px;
    height: 0.25rem;
    background: ${palette.white};
    border-radius: 10px;
    transition: all 0.1s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: rotate(45deg) translateY(-3px) translateX(-1px);
    }

    :nth-child(2) {      
      transform: rotate(-45deg) translateY(2px);
    }

    :nth-child(3) {
      opacity: 0;
    }
    
    }
  `}
    

  @media (max-width: 420px) and (orientation: portrait), (max-height: 390px) and (orientation: landscape){
    display: flex;
  }
`;

export interface IBurgerProperties {
  opened: boolean;
  onBurgerClick?: () => void;
}

const Burger = (props: IBurgerProperties) => {
  const { opened, onBurgerClick } = props;

  return (
    <StyledBurger open={opened} onClick={onBurgerClick}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default Burger;
