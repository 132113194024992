import { useUser } from "../containers/AuthProvider";
import { getRestrictionsByRole } from "../permissions/getRestrictionsByRole";

export const usePermissions = () => {
    // this will be used when user, as of now taking hardcoded list of restrictions
    const user = useUser();
    const permissions: string[] = ["*"]; // = user.permissions
    const restrictions: string[] = getRestrictionsByRole(user?.userRole); // = user.restrictions    
    return {permissions: permissions, restrictions: restrictions};
};

export const useIsControlRestricted = (controlId: string) => {
    const user = useUser();
    const restrictions: string[] = getRestrictionsByRole(user?.userRole); // = user.restrictions
    let idSearch: string = "*";
    if (controlId != "") {
        let idx = controlId.lastIndexOf("_");
        if (idx > 0) {
            idSearch = controlId.substring(0, idx);
        }
    }

    return restrictions && restrictions.includes(idSearch);
}