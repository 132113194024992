import React from "react";
import styled from "styled-components";
import { NavigationItemInterface } from "./types";
import { Link, useRouteMatch } from "react-router-dom";
import Icon from "../Icon";

const Tooltip = styled.span`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.15px;
  background-color: ${(props) => props.theme.navigator.tooltip.background};
  color: ${(props) => props.theme.navigator.tooltip.color};
  text-align: center;
  padding: 0 8px;
  border-radius: 3px;
  z-index: 2;
  transition: opacity 0.6s;
  top: -4px;
  left: 110%;
`;

const Container = styled(Link)<{
  $active: string;
  $isCollapsed: Boolean;
  $appearance: string;
}>`
  position: relative;
  min-height: 34px;
  min-width: 40px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 40px;
  color: ${(props) =>
    props.theme.navigator[props.$appearance].item[props.$active].color};
  background: ${(props) =>
    props.theme.navigator[props.$appearance].item[props.$active].background};
  cursor: pointer;
  border-radius: ${(props) => (props.$isCollapsed ? "20px" : "3px")};
  margin: ${(props) => (props.$isCollapsed ? "2px 12px" : "2px 8px")};
  padding: ${(props) => (props.$isCollapsed ? "0" : "0 12px")};
  ${(props) => props.$isCollapsed && "text-align: center;"}
  border: 0;
  &::-moz-focus-inner {
    border: 0;
    margin: 0;
    padding: 0;
  }
  &:hover,
  &:active {
    background: ${(props) =>
      props.theme.navigator[props.$appearance].item.active.background};
    text-decoration: none;
    color: ${(props) =>
      props.theme.navigator[props.$appearance].item.active.color};
  }
  &:focus {
    outline: 0;
  }
  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`;

const Count = styled.span``;
const Label = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-left: 8px;
  flex: 1;
`;

function NavigationItem({
  label,
  to,
  onClick,
  icon,
  iconName,
  count,
  rightIconName,
  isCollapsed = false,
  className,
  appearance = "main",
  exact = false,
  isShown = true,
}: NavigationItemInterface) {
  const match = useRouteMatch({
    path: to,
    exact: exact,
  });
  const isActive = to ? !!match : false;
  const active = isActive ? "active" : "default";
  if (isShown !== true) {
    return <></>;
  }
  return (
    <Container
      $appearance={appearance}
      as={to ? Link : "span"}
      $isCollapsed={isCollapsed}
      $active={active}
      {...{ to, onClick, className }}
    >
      {icon ? icon : <Icon size={24} name={iconName} />}
      {!isCollapsed && <Label>{label}</Label>}
      {count && <Count>{count}</Count>}
      {rightIconName && <Icon size={24} name={rightIconName} />}
      {isCollapsed && <Tooltip>{label}</Tooltip>}
    </Container>
  );
}
export default NavigationItem;
