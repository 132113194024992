import { useMemo, useState } from "react";
import { useUser } from "../../AuthProvider";
import { Badge } from "@mui/material";
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import AnnouncementBadgeMenuItem from "./AnnouncementBadgeMenuItem";
import React from "react";
import { BadgeButton, StyledDivider, StyledMenu } from "../styles";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ACKNOWLEDGE_ANNOUNCEMENT, GET_LATEST_ANNOUNCEMENTS } from "../gql";
import AnnouncementModal from "./AnnouncementModal";

const AnnouncementBadge = () => {
    const user = useUser();
    const [getLast15Announcements] = useLazyQuery(GET_LATEST_ANNOUNCEMENTS, {variables:{first: 15, order: {uTCDateSent: 'DESC'}}, fetchPolicy: 'no-cache'});
    const [last15Announcements, setLast15Announcements] = React.useState<any>([]);
    const [acknowledgementAttempted, setAcknowledgementAttempted] = React.useState<boolean>(false);
    const [acknowledgeAnnouncement] = useMutation(ACKNOWLEDGE_ANNOUNCEMENT);
    const [latestAnnouncement, setLatestAnnouncement] = React.useState<any>();
    const [selectedAnnouncement, setSelectedAnnouncement] = React.useState<any>();

    const getLast15AnnouncementsAsync = async () => {
        let response = await getLast15Announcements();
        if(response.error 
        || !response.data?.latestAnnouncements?.edges
        || response.data.latestAnnouncements.edges.length === 0){
        return undefined;
        }

        return response.data.latestAnnouncements.edges.map((x: any) => x.node);
    };

    const getLatestAnnouncementAsync = async (previousAnnouncement: any, currentAnnouncement: any) => {
        //If we are viewing an announcement then just return until they have acknowledged/closed it
        if(currentAnnouncement) return;
        let last15 = await getLast15AnnouncementsAsync();
        if(!last15) {
        setLast15Announcements([]);
        return;
        }
        setLast15Announcements(last15);
        let latestAnnouncement = last15[0];

        //If the new data is not the same as the latest announcement we know of and we havnt acknowledged it
        if(latestAnnouncement.id !== previousAnnouncement?.id 
        && !latestAnnouncement.acknowledgements.some((x: any) => x.userId == user?.id)){
        setLatestAnnouncement(latestAnnouncement);
        setSelectedAnnouncement(latestAnnouncement);
        setAcknowledgementAttempted(false);
        }
    }

    React.useEffect(() => {
        //If we are not logged in, do not make a call for the announcement
        if(!user) return;
        // Set up the timer
        const interval = setInterval(async () => {
        await getLatestAnnouncementAsync(latestAnnouncement, selectedAnnouncement);
        }, 30000); // 30 seconds

        // Clean up the interval when the component unmounts
        return () => clearInterval(interval);
    }, [user, latestAnnouncement, selectedAnnouncement]);

    const handleAnnouncementClosedAsync = async () => {
        setAcknowledgementAttempted(true);
        setSelectedAnnouncement(undefined);
        if(selectedAnnouncement.acknowledgements.some((x: any) => x.userId == user!.id))
        return;

        await acknowledgeAnnouncement({variables: {
        announcementId: selectedAnnouncement.id
        }});

        //Update the last 15 so it shows we have acknowledged it
        setLast15Announcements((prevItems: any) => 
        prevItems.map((item: any) =>
            // Find the item to update by checking its ID
            item.id === selectedAnnouncement.id
            ? {
                ...item, // Copy the item
                acknowledgements: [
                    ...item.acknowledgements, // Copy the existing acknowledgements array
                    {userId: user!.id}, // Add the new acknowledgement
                ],
                }
            : item // Return unchanged item if it doesn't match
        )
        );
    };

    const onAnnouncementSelected = (announcement: any) => {
        setSelectedAnnouncement(announcement);
        setAcknowledgementAttempted(false);
    }

    const latestAnnouncements = useMemo(() => last15Announcements ?? [], [last15Announcements]);
    const numberOfUnreadAnnouncements = latestAnnouncements.filter((x: any) => !x.acknowledgements.some((y: any) => y.userId === user.id))?.length ?? 0;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleMenuItemSelected = (selectedAnnouncement: any) => {
        setAnchorEl(null);
        onAnnouncementSelected(selectedAnnouncement);
    }
    return (
        <>
            {/* If there is an announcement, show it and handle the acknowledgement */}
            {!acknowledgementAttempted && selectedAnnouncement && 
                <AnnouncementModal
                    content={selectedAnnouncement.item.details}
                    title={selectedAnnouncement.name}
                    onClose={handleAnnouncementClosedAsync}
                />
            }    
            <BadgeButton onClick={handleClick} active={false}>
                <Badge badgeContent={numberOfUnreadAnnouncements} color="error">
                    <CircleNotificationsIcon style={{color: 'white'}} />
                </Badge>
            </BadgeButton>
            <StyledMenu
                aria-labelledby={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {latestAnnouncements.length === 0 ? (
                    <AnnouncementBadgeMenuItem user={user}/>
                ) : 
                (
                    latestAnnouncements.map((x:any, index: number) => (
                        <div key={x.id}>
                            <AnnouncementBadgeMenuItem onSelected={handleMenuItemSelected} user={user} announcement={x}/>
                            {index < latestAnnouncements.length - 1 && <StyledDivider />}
                        </div>
                    ))
                )
                }
            </StyledMenu>
        </>
    );
};

export default AnnouncementBadge;