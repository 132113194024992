import React from "react";
import { useApolloClient } from "@apollo/client";
import {
  AuthTokens,
  removeTokens,
  saveSharedToken,
  saveTokens,
} from "../services/apolloclient/accessTokens";
import { ME } from "../common/gql";
import { User } from "../common/types";
import jwtDecode from "jwt-decode";
import { getItem } from "../services/clientStorage";
import { LOCAL_STORAGE_TEMPLATE } from "../constants";
import { config } from "./Config";

type AuthContextState = {
  logout: () => void;
  setUser: (user: User) => void;
  user?: User;
  setAuthTokens: (authTokens: AuthTokens) => void;
  setSharedAuthToken: (accessToken: string) => Promise<void>;
  authTokens?: AuthTokens;
  updateUserProfile: () => void;
};

export const AuthContext = React.createContext<AuthContextState>(
  {} as AuthContextState
);

export const useUser = (): User => {
  const { user } = React.useContext(AuthContext);
  return user!;
};

export const useAuthTokens = (): AuthTokens => {
  const { authTokens } = React.useContext(AuthContext);
  return authTokens!;
};

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<User>();
  const [authTokens, setAuthTokens] = React.useState<AuthTokens>();
  const client = useApolloClient();

  const updateUserProfile = React.useCallback(async () => {
    try {
      const accessToken = await getItem(LOCAL_STORAGE_TEMPLATE.accessToken);
      if (!accessToken) return;
      var x: any = jwtDecode(accessToken);
      if (x.UserType === "ShareableUser") {
        return;
      }
      const { data } = await client.query({ query: ME });
      if (data && data.userProfile) {
        setUser({
          id: data.userProfile.id,
          fullName: `${data.userProfile.firstName} ${data.userProfile.lastName}`,
          primaryEmail: data.userProfile.primaryEmail,
          avatarImg:
            data.userProfile.azurePathReference &&
            `${config().REACT_APP_AVATAR_URL}${data.userProfile.azurePathReference}`, //&dt=${Date.now()}`, fixed on the server side
          countryReference: data.userProfile.countryReference,
          customerId: data.userProfile.customer?.id,
          customerContactId: data.userProfile.customer?.customerContacts,
          userType: data.userProfile.userType,
          userRole: data.userProfile.userRole,
          operatorId:
            data.userProfile.customer === null
              ? data?.userProfile?.operatorId
              : data?.userProfile?.customer?.operatorId,
          permissions: ["*"], //TODO: read real OR hardcode correct set
          restrictions: [],
        });
      }
    } catch (err) {
      console.error("updateUserProfile / Error", err);
    }
  }, [client]);

  const setAuthTokensState = React.useCallback(
    async (authTokens: AuthTokens) => {
      try {
        await saveTokens(authTokens);
        setAuthTokens(authTokens);
        await updateUserProfile();
      } catch (err) {
        console.error("setAuthTokensState / Error", err);
      }
    },
    [updateUserProfile]
  );

  const setSharedAuthTokenState = async (accessToken: string) => {
    try {
      const currentAccessToken = await getItem(
        LOCAL_STORAGE_TEMPLATE.accessToken
      );
      if (currentAccessToken) {
        var x: any = jwtDecode(currentAccessToken);
        if (x.UserType !== "ShareableUser") {
          return;
        }
      }
      await saveSharedToken(accessToken);
    } catch (err) {
      console.error("setSharedAuthTokenState / Error", err);
    }
  };

  const logout = React.useCallback(async () => {
    try {
      setAuthTokens(undefined);
      setUser(undefined);
      await removeTokens();
      await client.resetStore();
    } catch (err) {
      console.error(err);
    }
  }, [client]);

  React.useImperativeHandle(AuthContextRef, () => ({
    logout,
    setUser,
    setAuthTokens: setAuthTokensState,
    setSharedAuthToken: setSharedAuthTokenState,
    updateUserProfile,
  }));

  return (
    <AuthContext.Provider
      value={{
        user,
        authTokens,
        setUser,
        setAuthTokens: setAuthTokensState,
        setSharedAuthToken: setSharedAuthTokenState,
        logout,
        updateUserProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthContextRef = React.createRef<AuthContextState>();
