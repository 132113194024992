import React from "react";
import { BreadcrumbsProps } from "./types";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  & > li {
    display: inline;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    & > span,
    a {
      text-decoration: none;
      color: ${(props) => props.theme.colors.subtitle};
    }
    & > a:hover {
      color: ${(props) => props.theme.colors.text};
    }
  }
  & > li + li:before {
    padding: 4px;
    color: ${(props) => props.theme.colors.subtitle};
    content: "/";
  }
`;

const Item = styled(Link)``;

export default class Breadcrumbs extends React.PureComponent<BreadcrumbsProps> {
  render() {
    const { links } = this.props;

    return (
      <Container>
        {links &&
          links.length &&
          links.map(({ label, ...rest }, index) => (
            <li key={`breadcrumbItm_${index}`}>
              <Item as={rest.to ? Link : "span"} {...rest}>
                {label}
              </Item>
            </li>
          ))}
      </Container>
    );
  }
}
