import { palette } from "../../themes/palette";
import styled, { keyframes } from "styled-components";
import { LoaderPropTypes } from "./types";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div<LoaderPropTypes>`
  display:  ${(props) => (props.display)};
  align-self: center;
  border-radius: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: ${(props) => (props.form && !props.small ? 31 : 2)}px;
  margin-right: ${(props) => (props.form && !props.small ? 31 : 2)}px;
  border: 2px solid ${palette.navy};
  border-bottom-color: transparent;  
  width: ${(props) => (props.small ? 12 : 22)}px;
  height: ${(props) => (props.small ? 12 : 22)}px;
  animation: ${rotate} 0.75s linear infinite;
`;

export const LoadingLayer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0;
  width: 100%;
  height: 100%;
`;

export const LoadingRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0;
  width: 100%;
  height: 50px;
`;

export const LoadingMoreLayer = styled.div`
  pointer-events: none;
  background: rgba(32, 60, 94, 0.3);
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

export const LoadingMoreText = styled.span`
  color: #fff;
  margin-left: 5px;
`;

export default Loader;