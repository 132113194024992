import { setContext } from '@apollo/client/link/context';
import { getAccessToken } from './accessTokens';

const authLink = setContext(async (operation, { headers }) => {
    const accessToken = await getAccessToken();
  
    return {
      headers: {
        ...headers,
        ...(accessToken && {authorization: `Bearer ${accessToken}`}),
      },
    };
  });

 export default authLink;