import { gql } from "@apollo/client";

export const GET_METRICS = gql`
  query ($dashboardFilter: DashboardFilterInput!) {
    allMetrics(dashboardFilter: $dashboardFilter) {
      doughnutCharts {
        title
        data
        labels
      }
      barCharts {
        title
        data
        labels
      }
      keyValueMetrics {
        key
        value
      }
      lineGraphs {
        titleOne
        titleTwo
        labels
        columnOneData
        columnTwoData
      }
      tables {
        headers {
          accessor
          header
        }
        data {
          columnName
          data
        }
      }
    }
  }
`;

export const GET_ALL_CUSTOMERS = gql`
  query {
    allCustomer {
      id
      name
      reference
    }
  }
`;

export const GET_SITES = gql`
  query {
    allSites {
      id
      reference
      name
      customer {
        id
        reference
      }
      systemRecords {
        transmitterTypeId
        systemConnectionTransmitterType {
          id
          name
        }
      }
    }
  }
`;

export const GET_CREATABLE_TRANSMITTER_TYPES = gql`
  query {
    creatableTransmitterTypes {
      id
      name
      displayName
      isAudioSystem
    }
  }
`;

export const DOES_USER_HAVE_METRICS = gql`
  query {
    doesUserHaveMetrics
  }
`;

export const REQUEST_DASHBOARD_EXPORT = gql`
  mutation ($startTime: DateTime!, $endTime: DateTime!) {
    requestDashboardExport(startTime: $startTime, endTime: $endTime)
  }
`;

export const GET_DASHBOARD_LAYOUT = gql`
  query {
    dashboardLayout {
      x
      y
      w
      h
      name
      minW
      minH
      maxW
      maxH
      membershipId
      operatorId
    }
  }
`;

export const EDIT_DASHBOARD_LAYOUT = gql`
  mutation ($dashboardLayout: [DashboardLayoutInput]) {
    editDashboardLayout(dashboardLayout: $dashboardLayout)
  }
`;
