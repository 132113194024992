import { useLazyQuery } from "@apollo/client";
import { debounce } from "lodash";
import { FC, ReactElement, useCallback, useRef, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import DownloadButton from "../../containers/IncidentReports/components/DownloadButton";
import { palette } from "../../themes/palette";
import Icon from "../Icon";
import { GLOBAL_SEARCH } from "./gql";
import {
  GlobalSearch,
  Reports,
  Search,
  SearchDialogProps,
  Sites,
  Users,
} from "./types";
import { config } from "../../containers/Config";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const SearchPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 100%;
  background-color: ${palette.white};
  overflow: hidden;

  filter: drop-shadow(8px 0px 16px rgba(37, 74, 93, 0.25));
  animation-duration: 0.5s;
  animation-name: slidein;

  @keyframes slidein {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
    }
  }

  &.close {
    animation-name: slideout;
    transform: translateX(-100%);

    @keyframes slideout {
      from {
        transform: translateX(0);
      }

      to {
        transform: translateX(-100%);
      }
    }
  }
`;

const Logo = styled.div`
  padding: 23px 12px;
`;

const BtnBack = styled.button`
  padding: 0 21px;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 24px;
  margin-bottom: 20px;
`;

const SearchField = styled.input`
  width: 100%;
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  border: none;
  outline: none;
  box-sizing: border-box;

  &:focus {
    border-bottom: 2px solid rgb(38, 132, 255);
  }

  &::placeholder {
    color: #b3bac5;
  }
`;

const List = styled.ul`
  flex: 1;
  margin: 0;
  padding: 0 24px 20px 70px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.15px;
  color: ${palette.navy};
  list-style-type: none;
  overflow-y: auto;
`;

const LoaderContainer = styled.div`
  text-align: center;

  .dot-loader {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${palette.purple};
    position: relative;
    -webkit-animation: 1.2s grow ease-in-out infinite;
    animation: 1.2s grow ease-in-out infinite;
  }

  .dot-loader--2 {
    -webkit-animation: 1.2s grow ease-in-out infinite 0.15555s;
    animation: 1.2s grow ease-in-out infinite 0.15555s;
    margin: 0 20px;
  }

  .dot-loader--3 {
    -webkit-animation: 1.2s grow ease-in-out infinite 0.3s;
    animation: 1.2s grow ease-in-out infinite 0.3s;
  }

  @keyframes grow {
    0%,
    40%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

const Item = styled.li`
  padding-top: 32px;
`;

const Title = styled.h3`
  padding-left: 6px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${palette.navy};
`;

const DataList = styled.ul`
  margin: 0;
  padding: 0;
  font-weight: normal;
  list-style-type: none;
`;

const DataItem = styled.li`
  margin: 0;
  padding: 12px 0 12px 6px;
  cursor: pointer;

  &:hover {
    background-color: rgba(193, 199, 208, 0.4);
  }
`;

const Text = styled.p`
  padding-top: 32px;
`;

const ReportItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-left: 6px;
`;

const ReportTitle = styled.div`
  padding-right: 6px;
`;

export const QuickSearch: FC<SearchDialogProps> = ({ handleClose }) => {
  const history = useHistory();
  const [route, setRoute] = useState("");
  const [isClose, setClose] = useState(false);
  const [value, setValue] = useState("");
  const containerRef = useRef(null);
  const [getResult, { data, loading }] =
    useLazyQuery<GlobalSearch>(GLOBAL_SEARCH);

  const lists: Record<keyof Search, (value: any) => string | ReactElement> = {
    sites: (value: Sites) => {
      const { name } = value;
      return `${name}`;
    },
    users: (value: Users) => {
      const { firstName, lastName } = value;
      return `${firstName} ${lastName}`;
    },
    reports: (value: Reports) => {
      const { fileName, customerId } = value;
      return (
        <ReportItem>
          <ReportTitle>{fileName}</ReportTitle>
          <DownloadButton fileName={fileName} customerId={customerId} />
        </ReportItem>
      );
    },
  };

  const closeDialog = () => {
    setClose(true);
  };

  const onAnimationEnd = () => {
    if (isClose) {
      handleClose();
    }
    if (route) {
      history.push(route);
      handleClose();
    }
  };

  const onClose = (event: React.MouseEvent) => {
    if (event.target === containerRef.current) {
      closeDialog();
    }
  };

  const onSearch = useCallback(
    debounce((event: React.ChangeEvent) => {
      const { value } = event.target as HTMLInputElement;
      setValue(value);
      getResult({ variables: { search: value } });
    }, 500),
    []
  );

  const toFoundedData =
    ({ name, id, azurePathReference }: any) =>
    () => {
      if (azurePathReference) {
        window.open(
          `${config().REACT_APP_INCIDENT_REPORT_URL}${azurePathReference}`,
          "_blank"
        );
      } else {
        setRoute(`/${name}/${id}`);
      }
      closeDialog();
    };

  const getSearchList = () => {
    return (
      (data &&
        Object.entries(data?.globalSearch).filter(
          (val) => val[1] instanceof Array && val[1].length
        )) ||
      []
    );
  };

  const searchList = getSearchList();

  return (
    <Container ref={containerRef} onClick={onClose}>
      <SearchPanel
        className={isClose ? "close" : ""}
        onAnimationEnd={onAnimationEnd}
      >
        <Logo>
          <img src="/images/logo-white-small.png" alt="logo" />
        </Logo>
        <ControlsContainer>
          <BtnBack onClick={closeDialog}>
            <Icon name="arrow-left" />
          </BtnBack>
          <SearchField type="text" placeholder="Search…" onChange={onSearch} />
        </ControlsContainer>
        <List>
          {loading ? (
            <LoaderContainer>
              <div className="dot-loader"></div>
              <div className="dot-loader dot-loader--2"></div>
              <div className="dot-loader dot-loader--3"></div>
            </LoaderContainer>
          ) : (
            <>
              {searchList.length
                ? searchList.map(([name, value]) => {
                    return (
                      <Item key={name}>
                        <Title>{name}</Title>
                        <DataList>
                          {value.map((data: Sites | Users | Reports) =>
                            name === "reports" ? (
                              <li key={data.id}>{lists[name](data)}</li>
                            ) : (
                              <DataItem
                                key={data.id}
                                onClick={toFoundedData({ name, ...data })}
                              >
                                {lists[name](data)}
                              </DataItem>
                            )
                          )}
                        </DataList>
                      </Item>
                    );
                  })
                : value && <Text>No data found.</Text>}
            </>
          )}
        </List>
      </SearchPanel>
    </Container>
  );
};
