import { AudioLog } from "./types";
import { GET_SITE_LOGS } from "./gql";
import { useQuery } from "@apollo/client";

export const useGetLogs = (): { logs: AudioLog[] } => {
  const { data } = useQuery(GET_SITE_LOGS);
  const logs: { node: AudioLog }[] = data?.aILogPaging?.edges || [];

  return {
    logs: logs.map(({ node }: { node: AudioLog }) => node),
  };
};
