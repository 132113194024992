import { palette } from "./palette"

export const defaultTheme = {
  colors: {
    primary: palette.purple,
    secondary: '#3c1053',
    success: palette.green,
    error: palette.red,
    dark: '#272C34',
    stone: '#577F95',
    navy: '#254A5D',
    orange: '#ff7f00',
    text: palette.navy,
    subtitle: palette.navySecond,
    white: palette.white,
    lightGrey: palette.lightGrey,
    transparent: palette.transparent,
    yellow: palette.yellow,
    whiteSubtitle: 'rgba(255, 255, 255, 0.6)'
  },
  popupBkg: 'rgba(0, 0, 36, 0.6)',
  favoriteIconColor: palette.yellow,
  navigator: {
    main: {
      background: palette.navy,
      item: {
        default: {
          background: palette.navy,
          color: palette.white,
        },
        active: {
          background: palette.purple,
          color: palette.white,
        }
      },
    },
    page: {
      background: palette.purple,
      item: {
        default: {
          background: palette.purple,
          color: palette.white,
        },
        active: {
          background: 'rgba(255,255,255,0.4)',
          color: palette.white,
        }
      },
    },
    tooltip: {
      background: palette.violet,
      color: palette.white,
    }
  },
  avatar: {
    background: "#C1C7D0",
    color: "#FFFFFF",
  },
  button: {
    default: {
      background: {
        default: '#F5F6F7',
        hover: '#D2D5D8',
        active: '#E9EAEB',
        disabled: '#F5F6F7',
      },
      color: {
        default: '#254A5D',
        hover: '#254A5D',
        active: '#254A5D',
        disabled: palette.navyThird,
      },
    },
    primary: {
      background: {
        default: palette.purple,
        hover: '#5F5BEA',
        active: '#807DF1',
        disabled: palette.navyThird,
      },
      color: {
        default: palette.white,
        hover: palette.white,
        active: palette.white,
        disabled: palette.navyThird,
      },
    },
    link: {
      background: {
        default: palette.transparent,
        hover: palette.transparent,
        active: palette.transparent,
        disabled: palette.transparent,
      },
      color: {
        default: palette.purple,
        hover: '#5F5BEA',
        active: '#807DF1',
        disabled: palette.navyThird,
      },
    },
    delete: {
      background: {
        default: palette.transparent,
        hover: palette.transparent,
        active: palette.transparent,
        disabled: palette.transparent,
      },
      color: {
        default: palette.red,
        hover: palette.red,
        active: palette.red,
        disabled: palette.navyThird,
      },
    },
    cancel: {
      background: {
        default: palette.transparent,
        hover: palette.transparent,
        active: palette.transparent,
        disabled: palette.transparent,
      },
      color: {
        default: palette.navySecond,
        hover: palette.navySecond,
        active: palette.navySecond,
        disabled: palette.navyThird,
      },
    },
  },
  input: {
    background: {
      default: '#FAFBFC',
      focused: palette.white,
      invalid: palette.white,
      disabled: '#F4F5F7'
    },
    borderColor: {
      default: '#DFE1E6',
      focused: palette.purple,
      invalid: palette.red,
      disable: palette.navyThird,
    },
    color: {
      default: palette.navy,
      disabled: palette.navySecond,
    },
    placeholderColor: palette.navySecond,
  },
  label: {
    color: {
      default: palette.navy,
      disabled: palette.navySecond,
    },
    requiredIndicator: palette.red,
  },
  form: {
    errorColor: palette.red,
    warningColor: palette.orange,
  },
  radio: {
    border: {
      default: '#DFE1E6',
      active: palette.purple
    }
  }
};

declare module 'styled-components' {
  type Theme = typeof defaultTheme;
  export interface DefaultTheme extends Theme {}
}
