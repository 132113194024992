import { ApolloClient, from, HttpLink } from '@apollo/client';
import errorLink from './errorLink';
import authLink from './authLink';
import cache from './cache';
import { config } from '../../containers/Config';

export const getApiClient = () => {
  let httpLink = new HttpLink({
    uri: config().REACT_APP_API_URL,
    credentials: 'same-origin',
  });
  
  return new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache,
    credentials: 'include',
  });
}