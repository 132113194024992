import React, { FC, ReactElement, useState } from "react";
import styled from "styled-components";
import { palette } from "../../themes/palette";
import { Link, useRouteMatch } from "react-router-dom";
import { NavigationItemInterface } from "./types";

export type OptionType = "text" | "title" | "ref";

interface CNavProps {
  children: JSX.Element | undefined;
  options?: NavigationItemInterface[];
}

interface CItemProps {
  to?: string;
  href?: string;
  type?: OptionType;
}

interface ItemProps {
  active: string;
  type?: OptionType;
}

const Container = styled.div`
  flex: 1;
  position: relative;
  display: inline-block;
  width: 100%;
`;

const Navigation = styled.div`
  position: absolute;
  display: none;
  flex-direction: column;
  bottom: 0;
  left: calc(100% - 10px);
  padding: 16px;
  border-radius: 3px;
  background: ${palette.white};
  box-shadow: 0 0 1px rgba(9, 30, 66, 0.31), 0 8px 16px rgba(9, 30, 66, 0.25);
  z-index: 2;
  will-change: transform;
  transform: translateZ(0);

  &.show {
    display: inline-flex;
  }
`;

const Item = styled(Link)<ItemProps>`
  color: ${(props) => {
    const isNavy = props.active === "default" && props.type === "ref";
    return isNavy ? palette.navy : "rgba(37, 74, 93, 0.5)";
  }};
  font-weight: 500;
  font-size: ${(props) => (props.type === "text" ? "12px" : "14px")};
  line-height: ${(props) => (props.type === "text" ? "16px" : "32px")};
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
`;

const CompactItem: FC<CItemProps> = ({
  to,
  type = "ref",
  children,
  ...rest
}): ReactElement => {
  const match = useRouteMatch({
    path: to,
    exact: false,
  });

  const active = match ? "active" : "default";

  return (
    <Item
      active={active}
      as={to ? Link : rest.href ? "a" : "span"}
      {...{ to, type }}
      {...rest}
    >
      {children}
    </Item>
  );
};

const CompactNavigation = (props: CNavProps): ReactElement => {
  const [isShow, setShow] = useState<boolean>(false);

  const { options, children = "" } = props;

  const handleEnter = () => {
    setShow(true);
  };

  const handleLeave = () => {
    setShow(false);
  };

  return (
    <Container onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
      {children}
      <Navigation className={isShow ? "show" : ""}>
        {options?.map((option) => (
          <CompactItem
            to={option?.to}
            type={option?.type}
            key={option.label}
            {...option}
          >
            {option?.type === "title"
              ? option.label.toUpperCase()
              : option.label}
          </CompactItem>
        ))}
      </Navigation>
    </Container>
  );
};

export default React.memo(CompactNavigation);
