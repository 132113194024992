export const palette = {
  black: "#1d1d1d",
  white: "#ffffff",
  stone: "#000024",
  skyBlue: "#009FDF",
  aqua: "#41B6E6",
  red: '#9B2242',
  lightRed: '#F17676',
  yellow: '#F1C400',
  transparent: 'rgba(0, 0, 0, 0)',
  lightGrey: '#C1C7D0',
  lightNatural: '#6B778C',
  navy: "#000024",
  navySecond: "rgba(0, 0, 36, 0.6)",
  navyThird: "rgba(0, 0, 36, 0.25)",
  purple: '#625DF3',
  blue: '#16DDFC',
  violet: '#7C3DC4',
  green: '#35D38C',
  orange:  "#f56c1f",
  lilac: "#dfdeff",
  azalea: "#f7c7c7"
};
