import React from 'react';
import { ApolloClient, createHttpLink, fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { REFRESH_TOKEN } from './gql';
import cache from './cache';
import { getTokens, removeTokens, saveTokens } from './accessTokens';
import { toast } from 'react-toastify';
import { AuthContextRef } from '../../containers/AuthProvider';
import { config } from '../../containers/Config';
//import { GET_CURRENT_USER } from 'queries'

let isRefreshing = false;
let pendingRequests: Function[] = [];

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

const addPendingRequest = (pendingRequest: Function) => {
  pendingRequests.push(pendingRequest);
};


export const setRenewTokenApiClient = () => {
  return new ApolloClient({
    link: createHttpLink({
      uri: config().REACT_APP_API_URL,
      credentials: 'same-origin',
    }),
    cache,
    credentials: 'include',
  });
}

let renewTokenApiClient = setRenewTokenApiClient();

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const getNewToken = async () => {
  const tokens = await getTokens();

  const { data } = await renewTokenApiClient.mutate({
    mutation: REFRESH_TOKEN,
    variables: {
      accessToken: tokens?.accessToken,
      refreshToken: tokens?.refreshToken,
    },
  });

  if (
    data &&
    data.accessToken &&
    data.refreshToken &&
    data.refreshToken.tokenString
  ) {
    await saveTokens({
      accessToken: data.accessToken,
      refreshToken: data.refreshToken.tokenString,
    });
  } else {
    await removeTokens();
  }
};

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  console.log('errorLink', graphQLErrors);

  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions && err.extensions.code) {
        switch (err.extensions.code) {
          case 'DEALER_IS_ON_CREDIT_HOLD':
            toast.error(err.message, {autoClose: 6000});
            return fromPromise(
              new Promise<void>(async (resolve) => {
                AuthContextRef.current?.logout();
                resolve();
              }),
            ).flatMap(() => {
              return forward(operation);
            });
          case 'AUTH_TOKEN_EXPIRED':
            if (!isRefreshing) {
              setIsRefreshing(true);
              return fromPromise(
                getNewToken().catch(async () => {
                  resolvePendingRequests();
                  setIsRefreshing(false);
                  //localStorage.clear()

                  // Cache shared with main client instance
                  /*renewTokenApiClient!.writeQuery({
                    query: GET_CURRENT_USER,
                    data: { currentUser: null },
                  })
                  */

                  AuthContextRef.current?.logout();
                  //await removeTokens();

                  return forward(operation);
                }),
              ).flatMap(() => {
                resolvePendingRequests();
                setIsRefreshing(false);

                return forward(operation);
              });
            } else {
              return fromPromise(
                new Promise<void>((resolve) => {
                  addPendingRequest(() => resolve());
                }),
              ).flatMap(() => {
                return forward(operation);
              });
            }
          case 'AUTH_NOT_AUTHENTICATED':
          case 'AUTH_TOKEN_INVALID':
            return fromPromise(
              new Promise<void>(async (resolve) => {
                AuthContextRef.current?.logout();
                resolve();
                //await removeTokens();
              }),
            ).flatMap(() => {
              return forward(operation);
            });
          case 'AUTH_NOT_AUTHORIZED':
            console.log(
              `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`,
            );
            break;
          case 'VALIDATION_ERROR':
            console.log('Error for form');
            break;
          default:
            toast.error(err.message, {autoClose: 6000});
            console.log(
              `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`,
            );
        }
      } else if (
        graphQLErrors &&
        graphQLErrors.length &&
        graphQLErrors.length > 0
      ) {
        toast.error(graphQLErrors[0].message);
      }
    }
  }
});

export default errorLink;
