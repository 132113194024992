import { MenuItem } from "@mui/material";
import styled from "styled-components";
import { User } from "../../../common/types";

type AnnouncementBadgeMenuItemProps = {
    user: User;
    announcement?: any;
    onSelected?: (announcement: any) => void;
};

const BadgeMenuItemContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const BadgeMenuItemIndicator = styled.div<{acknowledged: boolean}>`
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background-color: ${(props) => props.acknowledged ? 'transparent' : 'white'};
`

const NO_ANNOUNCEMENTS_MESSAGE = "No announcements to display...";
const MAX_TITLE_LENGTH = 50;

const AnnouncementBadgeMenuItem = (props: AnnouncementBadgeMenuItemProps) => {
    const isAcknowledged = props.announcement?.acknowledgements.some((y: any) => y.userId == props.user.id) ?? true;

    const getTitle = () => {
        if(!props.announcement)
            return NO_ANNOUNCEMENTS_MESSAGE;

        if(props.announcement.name.length <= MAX_TITLE_LENGTH)
            return props.announcement.name;

        return props.announcement.name.substring(0, MAX_TITLE_LENGTH - 3).padEnd(MAX_TITLE_LENGTH, '.');
    };

    const handleClick = () => {
        if(!props.onSelected) return;
        props.onSelected(props.announcement);
    };

    return (
        <MenuItem onClick={handleClick} style={{width: "100%", paddingLeft: "5px", paddingRight: "10px"}}>
            <BadgeMenuItemContainer>
                <BadgeMenuItemIndicator acknowledged={isAcknowledged} />
                {getTitle()}
            </BadgeMenuItemContainer>
        </MenuItem>
    );
};

export default AnnouncementBadgeMenuItem;