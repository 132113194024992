import { padEnd } from "lodash";
import { FC } from "react";
import { useHistory, useParams } from "react-router";
import styled, { createGlobalStyle } from "styled-components";
import Button from "../../components/Button";
import { FormFieldProps } from "../../components/FormFields/types";
import PageTitleWithButtons from "../../components/PageTitleWithButtons";
import { ROUTES } from "../../constants";
import { defaultTheme } from "../../themes";
import { useGetFields } from "./useGetFields";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 430;
  max-width: 540px;
  margin: 0 auto;
  padding-top: 32px;
`;

interface LogDetailProps {
  id?: string;
}

export const FORM_FIELDS_LOGS: FormFieldProps[] = [
  {
    name: "id",
    label: "Id",
  },
  {
    name: "dateTimeEvent",
    label: "Event Date",
  },
  {
    name: "siteReference",
    label: "Site",
  },
  {
    name: "customerReference",
    label: "Customer",
    placeholder: "First name",
  },
  {
    name: "spacer",
  },
  {
    name: "message",
    label: "Message",
  },
  {
    name: "description",
    label: "Description",
  },
  {
    name: "oldObject",
    label: "Old Value",
  },
  {
    name: "newObject",
    label: "New Value",
  },
];

const Header = styled.div`
  width: 100%;
  padding-left: 145px;
  margin-bottom: 32px;
  box-sizing: border-box;
`;

const Main = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Label = styled.div`
  min-width: 145px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #254a5d;
  text-align: right;
  box-sizing: border-box;
`;

const Title = styled.div`
  flex: 1;
  padding-left: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${defaultTheme.popupBkg};
`;

const Spacer = styled.div`
  margin-bottom: 22px;
`;

const SmallSpacer = styled.div`
  margin-bottom: 24px;
`;

const ObjectJSON = styled.div`
  min-height: 64px;
  max-height: 250px;
  width: 100%;
  padding: 8px;
  margin-left: 8px;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #7a869a;
  background: #fafbfc;

  border: 2px solid #dfe1e6;
  box-sizing: border-box;
  border-radius: 3px;
  box-sizing: border-box;
  overflow: auto;
`;

const Scroll = styled.div`
  scroll-behaviour: smooth;
  height: 700px;
  overflow: inherit;
  overflow-y: scroll;">
`;

const Controls = styled.div`
  width: 100%;
  margin-top: 22px;
  text-align: right;

  & button {
    margin-left: 15px;
  }
`;

export const LogDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { fields, isMakeDisabled, changeLog } = useGetFields(id);
  const history = useHistory();

  function CreateOldList(values: any) {
    var rows = [];
    var split = values.replace(/[{()}]/g, "").split(",");

    for (var i = 0; i < split.length; i++) {
      var propertyAndValue = split[i].split("=");

      if (propertyAndValue[1] === " ") {
        propertyAndValue[1] = "NULL";
      }

      rows.push(
        <p>
          {propertyAndValue[0]}:{propertyAndValue[1]}
        </p>
      );
    }
    return rows;
  }

  function CreateNewList(values: any) {
    var rows = [];
    var split = values.replace(/[\[\\{}\\"\]']+/g, "").split(",");
    for (var i = 0; i < split.length; i++) {
      var propertyAndValue = split[i].split("=");

      if (propertyAndValue[1] === " ") {
        propertyAndValue[1] = "NULL";
      }

      rows.push(
        <p>
          {propertyAndValue[0]}:{propertyAndValue[1]}
        </p>
      );
    }
    return rows;
  }

  return (
    <Scroll>
      <Container>
        <Header>
          <PageTitleWithButtons
            breadcrumbLinks={[
              {
                label: "Logs",
                to: ROUTES.logs,
              },
              {
                label: id,
              },
            ]}
            pageTitle={"Log details"}
          />
        </Header>

        <Main>
          {fields.map((field) =>
            field.label ? (
              field.name === "newObject" || field.name === "oldObject" ? (
                <Row key={field.name}>
                  <Label>{field.label}</Label>
                  <ObjectJSON>
                    {field.value
                      ? field.name === "oldObject"
                        ? CreateOldList(field.value)
                        : CreateNewList(field.value)
                      : "-"}
                  </ObjectJSON>
                </Row>
              ) : (
                <Row key={field.name}>
                  <Label>{field.label}</Label>
                  <Title>{field.value ? field.value : "-"}</Title>
                </Row>
              )
            ) : (
              <Spacer key={field.name} />
            )
          )}
        </Main>

        <SmallSpacer></SmallSpacer>
        <Controls>
          <Button onClick={() => history.push("/logs")} appearance="cancel">
            Back to list
          </Button>
          <Button
            onClick={() => changeLog("prev")}
            disabled={isMakeDisabled("prev")}
            appearance="primary"
          >
            {"< Previous"}
          </Button>
          <Button
            onClick={() => changeLog("next")}
            disabled={isMakeDisabled("next")}
            appearance="primary"
          >
            {"Next >"}
          </Button>
        </Controls>
      </Container>
    </Scroll>
  );
};
