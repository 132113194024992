export const CUSTOMER_BASIC_RESTRICTIONS: string[] = [
  "alarm_response_add",
  "alarm_response_delete",
  "cameras_name_edit",
  "contact_add",
  "contact_edit",
  "schedules_exceptions_add",
  "schedules_exceptions_edit",
  "schedules_exceptions_delete",
  "schedules_zone_edit",
  "site_alarmresponse_edit",
  "site_alarmresponse_drag",
  "site_arming_change",
  "sitePlan_changeBackground",
  "sitePlan_unallocatedCameras",
  "sitePlan_edit",
  "zones_add",
  "zones_draganddrop",
  "zones_edit",
  "zones_remove",
  "visitor_edit",
  "zoned_audio",
];
