import { usePermissions } from "../hooks/usePermissions";

const withPermission = (Component: any) => {
  const ComponentParent = (props: any) => {
    const userAccess = usePermissions();
    const permissions: string[] = userAccess?.permissions;
    const restrictions: string[] = userAccess?.restrictions;

    const { requiredPermissions = ["*"], ...restProps } = props;
    const { appliedRestrictions = ["*"], ...denyProps } = props;
    const { id = "", ...idProps } = props;
    let idSearch: string = "*";
    if (id != "") {
      let idx = id.lastIndexOf("_");
      if (idx > 0) {
        idSearch = id.substring(0, idx);
      }
    }

    let hasPermission =
      (permissions && permissions.includes("*")) ||
      requiredPermissions.includes("*") ||
      !!permissions.find(
        (p) =>
          requiredPermissions.includes(p) || !!permissions.includes(idSearch)
      );

    if (
      restrictions &&
      restrictions.find((p) => appliedRestrictions.includes(p))
    ) {
      hasPermission = false;
    }

    if (idSearch != "*" && restrictions && restrictions.includes(idSearch)) {
      hasPermission = false;
    }

    return hasPermission ? (
      <Component {...restProps} {...denyProps} {...idProps} />
    ) : null;
  };

  return ComponentParent;
};

export default withPermission;
