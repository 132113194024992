import { Environment } from "../common/types";

let appEnvironment = Environment.EMEA;
// src/config.js
let configData: ConfigData = {
};

type ConfigData = {
    REACT_APP_LIVE_VIEW_TIMEOUT?: number;
    REACT_APP_NORTH_AMERICA_URL?: string;
    REACT_APP_IRELAND_URL?: string;
    REACT_APP_UNITED_KINGDOM_URL?: string;
    REACT_APP_EUROPE_URL?: string;
    REACT_APP_AUSTRALIA_URL?: string;
    REACT_APP_NEW_ZEALAND_URL?: string;
    REACT_APP_AVATAR_URL?: string;
    REACT_APP_API_URL?: string;
    REACT_APP_TERMS_AND_CONDITIONS_URL?: string;
    REACT_APP_PRIVACY_POLICY_URL?: string;
    REACT_APP_BUILD_VERSION?: string;
    REACT_APP_OLD_SITE_URL?: string;
    REACT_APP_DEFAULT_PAGINATION_AMOUNT?: number;
    REACT_APP_SEARCH_TIMEOUT?: number;
    REACT_APP_REQUEST_PIN_TIMEOUT?: number;
    REACT_APP_INCIDENT_REPORT_URL?: string;
    REACT_APP_IMAGE_URL?: string;
    REACT_APP_COMPANY_LOGO_URL?: string;
    REACT_APP_TRAINING_ACADEMY_URL?: string;
    REACT_APP_RESOURCE_CENTER_URL?: string;
    REACT_APP_EMAIL_US_MAILTO_EMAIL?: string;
    REACT_APP_FT2_IMAGE_WAIT_SECONDS?: number;
    REACT_APP_ENVIRONMENT?: string;
    REACT_APP_IOS_STORE_LINK?: string;
    REACT_APP_ANDROID_STORE_LINK?: string;
    REACT_APP_CRM_MONITORING_HUB?: string;
    REACT_APP_SYNCFUSION_LICENSE?: string;
    REACT_APP_COMMISSIONING_BOOKING_EMAIL?: string;
}

let configLoaded = false;

export const loadConfig = async () => {
  if(configLoaded) return configData;
  
  const response = await fetch('/config.json');
  if (response.ok) {
    configData = await response.json();
    appEnvironment = configData.REACT_APP_ENVIRONMENT === "US" ? Environment.US : Environment.EMEA;
    configLoaded = true;
  } else {
    console.error('Failed to load configuration');
  }
};
export const config = (): ConfigData => configData;
export const APP_ENVIRONMENT = (): Environment => appEnvironment;