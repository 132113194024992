import {gql} from '@apollo/client';

export const GET_SITE_LOGS = gql`
 query (
    $first: Int,
    $after: String,
    $order: [AuditLogSortInput!],
    $where: AuditLogFilterInput, 
    $timeRangeReference: String!,
    $timeRangeStart: String,
    $timeRangeEnd: String) {
    aILogPaging (
      first: $first,
      after: $after,
      order: $order,
      where: $where,
      timeRangeReference: $timeRangeReference,
      timeRangeStart: $timeRangeStart,
      timeRangeEnd: $timeRangeEnd
    ) {
      edges {
        cursor
        node {
          id
          customerReference
          dateTimeEvent
          message
          siteReference
          user
          newObject
          oldObject
          userType
          operatorReference
          operatorName
          systemReference
          description
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_SITE_LOGS_EXPORT = gql`
 query (
    $order: [AuditLogSortInput!],
    $where: AuditLogFilterInput, 
    $timeRangeReference: String!,
    $timeRangeStart: String,
    $timeRangeEnd: String
  ) {
    aILogs (
      order: $order,
      where: $where,
      timeRangeReference: $timeRangeReference,
      timeRangeStart: $timeRangeStart,
      timeRangeEnd: $timeRangeEnd
    ) {
        id
        customerReference
        dateTimeEvent
        message
        siteReference
        user
        newObject
        oldObject
        userType
        operatorReference
        operatorName
        systemReference
        description
      }
    }
`;

export const GET_ALL_LOG_DATETIME_RANGES = gql`
query {
    allFilterDateTimeRanges {
        reference
        name
        name_de
    }
  }
`; 

export const GET_ALL_DEALERS = gql`
  query {
    allOperator{
        id
        name
        LogsName: name
      }
  }
`;