import { gql } from "@apollo/client";

export const GET_COUNTRIES = gql`
  query {
    allCountry(order: { countryReference: DESC }) {
      name
      name_de
      countryReference
    }
  }
`;

export const GET_STATES = gql`
  query ($countryReference: String) {
    allCountryRegion(
      where: { countryReference: { eq: $countryReference } }
      order: { name: ASC }
    ) {
      id
      name
    }
  }
`;

export const GET_COUNTRY_REFERENCE_BY_REGION_ID = gql`
  query {
    allCountryRegion {
      id
      countryReference
    }
  }
`;

export const GET_DEALERS = gql`
  query {
    allOperator(order: [{ name: ASC }]) {
      id
      name
    }
  }
`;

export const GET_INSTALLERS_MAINTAINERS = gql`
  query {
    allOperator(where: { isInstallerMaintainer: { eq: true } }) {
      id
      name
    }
  }
`;

export const GET_ACCOUNT_MANAGERS = gql`
  query {
    allAccountManagers {
      id
      firstName
      lastName
    }
  }
`;

export const DEALER_HAS_CHILDREN = gql`
  query {
    operatorUserHasChildren {
      id
    }
  }
`;

export const GET_DEALERS_IS_DEALER_FILTER = gql`
  query {
    allOperator(order: [{ name: ASC }]) {
      id
      name
      isDealer
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query {
    allCustomer {
      id
      name
    }
  }
`;

export const GET_TITLES = gql`
  query {
    allTitles(order: [{ name: ASC }]) {
      id
      name
      name_de
    }
  }
`;

export const GET_PHONE_TYPES = gql`
  query {
    allContactNumberType(order: [{ name: ASC }]) {
      id
      name
      name_de
    }
  }
`;

export const GET_TIME_ZONES = gql`
  query {
    allTimeZones(order: { details: { timeZoneName: ASC } }) {
      id
      timeZoneString_de
      details {
        timeZoneName
        utcDifference
      }
    }
  }
`;

export const ME = gql`
  query {
    userProfile {
      id
      firstName
      lastName
      primaryEmail
      permissions {
        permissionId
        action
      }
      userRole
      userType
      company {
        id
      }
      customer {
        operatorId
        customerContacts {
          id
        }
        id
      }
      operatorId
      countryReference
      azurePathReference
    }
  }
`;

export const GET_OPERATOR_ROLES = gql`
  query {
    allOperatorRoles {
      key
      value
    }
  }
`;

export const GET_CREATABLE_OPERATOR_ROLES = gql`
  query ($id: Int, $isEdit: Boolean, $isCreate: Boolean) {
    creatableOperatorRoles(
      operatorId: $id
      isEdit: $isEdit
      isCreate: $isCreate
    ) {
      key
      value
    }
  }
`;

export const GET_POLICY = gql`
  query ($siteId: Int!, $policyReference: String!) {
    isSiteOptedIntoPolicy(siteId: $siteId, policyReference: $policyReference)
  }
`;

export const GET_POLICY_SITE_MENU = gql`
  query ($siteId: Int!, $policyReference: String!) {
    isSiteOptedIntoPolicySiteMenu(
      siteId: $siteId
      policyReference: $policyReference
    )
  }
`;

export const GET_POLICY_FOR_OPERATOR = gql`
  query ($operatorId: Int!, $policyReference: String!) {
    isOperatorOptedIntoPolicy(
      operatorId: $operatorId
      policyReference: $policyReference
    )
  }
`;

export const UPDATE_SITE_IS_COMPLETE = gql`
  mutation ($siteId: Int!) {
    updateIsSiteComplete(siteId: $siteId)
  }
`;

export const GET_SITE_GROUPS = gql`
  query {
    siteGroups {
      id
      name
      displayName
      siteGroupId
      reference
    }
  }
`;

export const GET_ALL_COMMISSIONING_STATUS_TYPES = gql`
  query {
    systemCommissionStatuses {
      id
      name
      displayName
    }
  }
`;

export const GET_ALL_COMMISSIONING_STATUS_TYPES_SYSTEM = gql`
  query {
    allSystemCommissionStatuses {
      id
      name
      displayName
    }
  }
`;

export const GET_REGION_STRING = gql`
  query {
    region
  }
`;
