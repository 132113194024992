import { gql } from "@apollo/client";

export const GET_REPORTS_PAGING = gql`
  query (
    $first: Int
    $last: Int
    $after: String
    $order: [IncidentReportSortInput!]
    $where: IncidentReportFilterInput
  ) {
    incidentReportsPaging(
      first: $first
      last: $last
      after: $after
      order: $order
      where: $where
    ) {
      edges {
        cursor
        node {
          id
          pathToPDF
          dateTimeIncidentCreated
          interactiveIncidentReport
          incidentReportReference
          canViewInteractive
          shareableLinks {
            email
            uTCExpiryTime
          }
          customerId
          siteId
          site {
            name
            reference
            countryReference
            customer {
              id
              name
              reference
              operatorId
              operator {
                name
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_REPORTS_EXPORT = gql`
  query (
    $order: [IncidentReportSortInput!]
    $where: IncidentReportFilterInput
  ) {
    incidentReports(order: $order, where: $where) {
      id
      pathToPDF
      dateTimeIncidentCreated
      interactiveIncidentReport
      incidentReportReference
      canViewInteractive
      shareableLinks {
        email
        uTCExpiryTime
      }
      customerId
      siteId
      site {
        name
        reference
        countryReference
        customer {
          name
          reference
          operatorId
          operator {
            name
          }
        }
      }
    }
  }
`;

export const GET_REPORT_LINK = gql`
  query ($fileName: String!, $customerId: Int!) {
    siteIncidentReportLink(fileName: $fileName, customerId: $customerId)
  }
`;

export const GET_CUSTOMERS = gql`
  query {
    allCustomer {
      id
      name
      reference
      ReportReference: reference
    }
  }
`;

export const GET_ALL_SITES = gql`
  query ($id: Int) {
    allSites(where: { customerId: { eq: $id } }) {
      id
      name
      reference
      ReportReference: name
      customer {
        id
        name
        reference
      }
    }
  }
`;

export const GET_REGION_STRING = gql`
  query {
    region
  }
`;
